footer{
	background-color: darken(@gray-text,18%);
	border-top: 8px solid @graydark-color;
	padding-bottom: 54px;
	color: @white;

	@media (max-width: @screen-md-max){
		padding-bottom:0;

		.footer__col{
			float: left;
			display: block;
			width: 100%;

		}
	}

	a{
		color: @white;
		border: 0;
		&:hover{
			color: @yellow-color;
		}
	}

	h4.title{
		color: @white;
		margin: 35px 0;
		font-size: 22px;

		&:before{
			top: 4px;
			width: 10px;
    		height: 23px;
		}
	}

	ul{
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;
		font-size: 14px;
		li{
			width: 50%;
			float: left;

			a{
				padding: 3px 0;
				display: block;
			}
		}

	}

	.contact__map{
		width: 100%;
		height: 250px;

		@media (max-width: @screen-md-max){
			min-width: 200px;
		}

	}

	.copyright{
		margin: 54px auto 0;
		padding: 25px 50px;
		color: @white;
		background-color: @graydark-color;
		color: @white;
		font-size: 17px;

		.text-left{
			float: left;
		}
		.text-right{
			float: right;

			@media (max-width: @screen-md-max){
				float: left;
			}
		}
	}
}


.ukas{
    margin: 16px 0 0;
    float: left;
}
.ashton-100 {
    margin: 16px 0 0 50px;
    float: left;
}