// COLORS

@gray-color			       : #6d6d6d;
@gray-text			       : #6d6d6d;
@graylight-color	     : #f9f9f9;
@graydark-color		     : #373737;
@yellow-color		       : #be1900;
@yellowdark-color       : darken(@yellow-color,5%);
@yellowlight-color     : lighten(@yellow-color,5%);
@black				         : #000;
@white				         : #fff;
@brand-primary:     @yellow-color;


@mainfont			: "acumin-pro",sans-serif;


// FLEXBOX

.equal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:  flex;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
 
}
.equal > [class*='col-'] {
  display: flex; 
  display: -webkit-box; 
  display: -webkit-flex;
  display: -ms-flexbox;
  display:  flex;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

// FUNCTIONS
.transition-prop (@transition) {
  -webkit-transition: @transition;  
  -moz-transition:    @transition;
  -ms-transition:     @transition; 
  -o-transition:      @transition;
  transition:         @transition;
}
.transform-prop(@props) {
  -webkit-transform: @props;
  -moz-transform: @props;
  -o-transform: @props;
  -ms-transform: @props;
  transform: @props;
}

.border-radius (@radius: 5px) {
	-webkit-border-radius: @radius;
	-moz-border-radius: @radius;
	border-radius: @radius;
}

.box-shadow (@x: 0px, @y: 3px, @blur: 5px, @color: black) {
	-webkit-box-shadow: @x @y @blur @color;
	-moz-box-shadow: @x @y @blur @color;
	box-shadow: @x @y @blur @color;
}

@media (min-width: 1200px) {
    .container{
        width: 100%;
        max-width: 1200px;
    }
}