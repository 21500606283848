.list__news{
	margin-top: 60px;
	margin-bottom: 40px;
	.container.nopad{
		@media (max-width: @screen-md-max){
			padding-left:15px;
			padding-right: 15px;
		}
	}
	.box__news{
        a{
            border-bottom: 0;
        }
		&:first-child, &:nth-child(2){
			width: 50%;
			@media (max-width: @screen-md-max){
				width: 100%;
			}
			.image.fill{
				display: block;
                min-height: 285px !important;
				img{
				    
                    height: auto;
				}
			}
		}
		.content{
			background-color: @graylight-color;
		}
	}

	.single__new{
		margin-bottom: 80px;
		.image{
			img{
				width: 100%;
				height: auto;
			}
		}
		.content{
			background-color: @graylight-color;
			padding: 25px;
			.date__box{
				color: @yellow-color;
				font-weight: 600;
				font-size: 16px;
			}
			h1{
				font-size: 25px;
			}
			.single-content{
				margin-top: 15px;
				font-size: 14px;
				p{
					font-size: 14px;
				}
			}
		}
	}
	.box__news__list{
		margin-bottom: 20px;

		a{
			border: 0;
		}
		.date{
			background-color: @yellow-color;
			padding: 20px;

			.date__box{
				color: @white;
				text-align: center;
				height: 100%;

				span{
					font-size: 22px;
					font-weight: 600;
					line-height: 18px;
					@media (max-width: @screen-md-max){
						font-size: 16px;
					}
				}
			}
		}
		.content{
			background-color: @graylight-color;
			padding: 20px;
			.transition-prop(all 300ms cubic-bezier(0.19, 1, 0.22, 1));
		    color: @gray-text;
			margin-bottom: 0;
            font-size: 14px;

			h5{
				margin-top: 0;
				margin-bottom: 10px;
				font-size: 16px;
				color: @gray-text;
				text-transform: uppercase;

			}

		}
		&:hover{
			.content{
				background-color: #f0f0f0;
				padding-left: 30px;
			}
		}
	}
}

.view__more{
	margin-bottom: 80px;
}

section.map__contact{
	min-height: 450px;
	height: 50vh; 
}


	// FORM
.contact-form{


	span {
		display: block;
	    font-size: .85em;
	    padding-bottom: 0px;
	    text-transform: uppercase;
	    letter-spacing: 1px;
	    font-weight: 600;
	    text-indent: 5px;
	    color: #333;
	    padding-top: 4px;
	}

	input,
	textarea,
	select {
		font-size: 16px;
	    padding: 0px 15px;
	    color: @gray-text;
	    display: block;
	    width: 100%;
	    background: 0 0;
	    cursor: pointer;
	}

	input,
	textarea {
		border: 3px solid #e5e5e5;
	}

	textarea {
		max-height: 100px;
	}

	input:focus,
	textarea:focus,
	label:active + input,
	label:active + textarea {
		outline: none;
		border: 3px solid @yellow-color;
	}

	select:focus {
		outline: none;
	}


	input.btn-yellow {
		margin-top: 25px;
		border: 0;
		background-color: @yellow-color;
		color: @white;
		position: relative;
		text-transform: uppercase;
		font-weight: 600;
		padding: 13px 22px;
		border-radius: 0;
		-webkit-box-shadow: 0 8px 49px -10px rgba(1,1,1,.12);
		-moz-box-shadow: 0 8px 49px -10px rgba(1,1,1,.12);
		box-shadow: 0 8px 49px -10px rgba(1,1,1,.12);
		.transition-prop(all 500ms cubic-bezier(0.19, 1, 0.22, 1));


        @media (max-width: @screen-sm-max){
            font-size: 14px;
            padding-right: 12px;
        }
		span{
			display: inline-block;
			float: left;
			.transition-prop(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
			&.icon{
				position: relative;
				width: 25px;
				height: 25px;

				svg{
		    		width: 100%;
		    		height: 100%;
					top: 50%;
					.transform-prop(translateY(-50%));
					position: absolute;

					path{
						fill: @white;

					}
				}
			}
			&.text{
				font-size: 17px;
				line-height: 25px;
				text-transform: uppercase;
				font-weight: 600;
				padding-right: 15px;
			}
		}
		&:hover, &:active, &:focus{
			color: @white;
			-webkit-box-shadow: 0 8px 49px -10px rgba(1,1,1,.26);
			-moz-box-shadow: 0 8px 49px -10px rgba(1,1,1,.26);
			box-shadow: 0 8px 49px -10px rgba(1,1,1,.26);
			span{
				padding-right: 15px;
			}
		}
	}
}

.toggle-title {
    cursor: pointer;

}
.toggle-p {
   
}


.featured__innerproducts{
}



.featured__innerproducts, .list__innerproducts{	
	.box__innerproducts{
		margin-bottom: 60px;
        min-height: 350px;
        overflow: hidden !important;
        a{
            border-bottom: 0;
        }
        
		.image{
			position: relative;
			display: block;
			img{
				width: 100%;
				height: auto;
				.transition-prop(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
			}
			.date__box{
				position: absolute;
				bottom: 20px;
				
				display: block;
				background: @yellow-color;
				color: @white;
				width: 76px;
				height: 62px;
				text-align: center;
				span{
					display: block;
					font-size: 2em;
    				font-weight: 600;
    				line-height: .85em;
    				position: absolute;
    				top: 50%;
    				left: 50%;
    				.transform-prop(translateX(-50%) translateY(-55%));
				}
			}
		}
		.content{
				background-color: @graylight-color;
			.transition-prop(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
			padding: 30px;
		    font-size: 16px;
			color: @gray-text;
            height: 130px;
			.transition-prop(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
			h5{
				margin-top: 0;
				text-transform: uppercase;
				color: @gray-text;
				font-size: 18px;
				margin-bottom: 0px;
				.transition-prop(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
			}
		}
		&:hover{
			img{
				.transform-prop(scale(1.15,1.15));
			}
			.content{
				background-color: @yellow-color;
                color: @white;
				h5{
					color: @white;
				}
			}
		}
        div{
            width: 100%;
        }

	}
}
.featured__industries {
    h3.title {
        margin: 0px 0px 50px 0px !important;
    }

}

.box_industries
{
  overflow: hidden;
  text-align: center;
  position: relative;
  margin-bottom: 20px;

      img {
        width: 100%;
        max-height: 200px;
 
    }

}

.box_industries .content {
  background: rgba( 66, 139, 202, 0.9 );
  bottom: -78%;
  color: #fff;
  height: 100%;
  left: 0%;
  text-align: center;
  position: absolute;
  transition: bottom 0.5s ease;
  font-size: 15px;
  width: 90.5%;
  padding: 10px;
  margin: 0 15px;
    @media (max-width: @screen-md-max){
      width: 91%;
  }
  @media (max-width: @screen-sm-max){
      bottom: -82%;
      width: 92.5%;
  }

    h5{
        margin-top: 0;
        text-transform: uppercase;
        color: #fff;
        font-size: 18px;
        margin-bottom: 15px;
    }

}
.box_industries:hover .content
{
  bottom: 0%;
}


