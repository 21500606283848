header.slider__home {
    position: relative;

    .slider {
        max-height: 470px;
        overflow: hidden;

        .slide {
            position: relative;

            .bg__image {
                width: 100%;
                height: 100%;
                background-position: 50% 50%;
                background-size: cover;
                position: absolute;
                top: 0;
                left: 0;
            }

            .overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                right: 0;
                background-color: rgba(255,255,255,0.08);
            }

            .container__info {
                position: relative;
                padding: 60px 60px 310px 60px;
                float: left;
                max-width: 500px;
                background-color: rgba(255,255,255,0.75);


                h2 {
                    text-transform: uppercase;
                    font-size: 2em;
                }

                h3 {
                    line-height: 29px;
                    font-size: 22px;
                }

                .link__more {
                    margin: 22px 0 0px;
                }

                @media (max-width: @screen-md-max) {
                    width: 100vw;
                }
            }
        }
    }

    .arrows__slider {
        display: none;
        &.show{
            display: block;
        }

            @media (max-width: @screen-sm-max){
                display: none;
            }
        .background__c {
            background: rgba(0,0,0,0.6);
            position: absolute;
            top: 50%;
            width: 50px;
            height: 50px;
            .transform-prop(scale(-1,1) translateY(-50%));
            .transition-prop(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
        }

        .prev {
            &:hover {
                .background__c{
                     background: @brand-primary;
                }
            }

            left: -10px;

            svg {
                height: auto;
                width: 40px;
                left: 7px;
                position: relative;
                top: 5px;

                path {
                    fill: white;
                }
            }
        }

        .next {
            right: 6px;


            .background__c {
                .transform-prop(scale(1,1) translateY(-50%));
            }

            &:hover {
                .background__c {
                    background: @brand-primary;
                }
            }

            svg {
                height: auto;
                width: 40px;
                position: relative;
                left: 7px;
                top: 5px;

                path {
                    fill: white;
                }
            }
        }

        .prev, .next {
            position: absolute;
            display: block;
            top: 0;
            width: 54px;
            padding: 10px;
            height: 100%;
            cursor: pointer;

            &:hover {
                svg {
                    .opacity(.8);
                }
            }

        }
    }
}

.sec__description{
	background: #333;
    text-transform: uppercase;
	h2{
		color: @white;
		font-weight: 400;
		font-size: 23px;
		margin: 30px 0;

		@media (min-width: @screen-md-max){
			margin: 40px;
			line-height: 1.4em;
		}
	}
}


.featured__products{
	background-color: @graylight-color;
}

.text__section{
	padding-bottom: 25px;
	color: @gray-text;
    font-size: 1.4em;
    line-height: 1.6em;
}


.featured__news{
	background-color: @graylight-color;
}



.featured__news, .list__news{	
	.box__news{
		margin-bottom: 60px;
        a{
            border-bottom: 0;
        }
		.image{
			position: relative;
			display: block;
			img{
				width: 100%;
				height: auto;
				.transition-prop(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
			}
			.date__box{
				position: absolute;
				bottom: 20px;
				
				display: block;
				background: @yellow-color;
				color: @white;
				width: 76px;
				height: 62px;
				text-align: center;
				span{
					display: block;
					font-size: 2em;
    				font-weight: 600;
    				line-height: .85em;
    				position: absolute;
    				top: 50%;
    				left: 50%;
    				.transform-prop(translateX(-50%) translateY(-55%));
				}
			}
		}
		.content{
			background-color: @white;
			.transition-prop(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
			padding: 30px;
		    font-size: 16px;
			color: @gray-text;
			.transition-prop(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
			h5{
				margin-top: 0;
				text-transform: uppercase;
				color: @gray-text;
				font-size: 18px;
				margin-bottom: 20px;
				.transition-prop(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
			}
		}
		&:hover{
			img{
				.transform-prop(scale(1.15,1.15));
			}
			.content{
				background-color: @yellow-color;
                color: @white;
				h5{
					color: @white;
				}
			}
		}
	}
}

