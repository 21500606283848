body{
	font-family: @mainfont;
	font-size: 12px;
	letter-spacing: .05em;
	overflow-x: hidden;
}

*{
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
}


a{
	outline: 0;
	color: @black;
	border-bottom: 1px solid;
	.transition-prop(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
	&:hover, &:active, &:focus{
		text-decoration: none;
		outline: 0;
		color: @black;
		border-color: @yellow-color;
	}
}

.nopad{
	padding-left: 0;
	padding-right: 0;
}

p{
	font-size: 1.2em;
}
ul {
    font-size: 1.2em;
    overflow: hidden;
}
h1,h2,h3,h4,h5,h6{
	font-weight: 600;
	letter-spacing: 0;

}

.fill {
    display:flex;
    justify-content:center;
    align-items:center;
    overflow:hidden
}
.fill img {
    flex-shrink:0;
    min-width:100%;

}

.title{
	&:before{
		display: inline-block;
		content: '';
		width: 7px;
		height: 19px;
		background-color: @yellow-color;
		margin-right: 15px;
		position:relative;
		top:1px;
	}

	h1&{
		color: @gray-text;
		margin: 50px 0;
		font-size: 28px;
	}
}

.light{
	font-weight: 400;
}


.btn{
	border: 0;
	outline: 0;
	&:focus, &:active, &:active:focus{
		outline: none;
	}

}

img{
	max-width: 100%;
	height: auto;
}


.btn-yellow{
	background-color: @yellow-color;
	color: @white;
	position: relative;
	text-transform: uppercase;
	font-weight: 600;
	padding: 13px 22px;
	border-radius: 0;
	-webkit-box-shadow: 0 8px 49px -10px rgba(1,1,1,.12);
	-moz-box-shadow: 0 8px 49px -10px rgba(1,1,1,.12);
	box-shadow: 0 8px 49px -10px rgba(1,1,1,.12);
	.transition-prop(all 500ms cubic-bezier(0.19, 1, 0.22, 1));

	span{
		display: inline-block;
		float: left;
		.transition-prop(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
		&.icon{
			position: relative;
			width: 25px;
			height: 25px;

			svg{
	    		width: 100%;
	    		height: 100%;
				top: 50%;
				.transform-prop(translateY(-50%));
				position: absolute;

				path{
					fill: @white;

				}
			}
		}
		&.text{
			font-size: 17px;
			line-height: 25px;
			text-transform: uppercase;
			font-weight: 600;
			padding-right: 15px;

            @media (max-width: @screen-sm-max){
                font-size: 14px;
                padding-right: 12px;
            }

		}
	}
	&:hover, &:active, &:focus{
		color: @white;
		-webkit-box-shadow: 0 8px 49px -10px rgba(1,1,1,.36);
		-moz-box-shadow: 0 8px 49px -10px rgba(1,1,1,.36);
		box-shadow: 0 8px 49px -10px rgba(1,1,1,.36);
		span{
			padding-right: 15px;
		}
	}
}


section{
	padding-left: 20px;
	padding-right: 20px;
}

main.main{
	margin-top: 188px;
	.transition-prop(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
	@media (max-width: 1114px){
		margin-top:90px;
	}
}
.no-margin-top {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
.FormCbxtuff {
    margin-bottom: 7px;
    clear: both;
    display: block;
    
    label {
        float: left;
        width: 100%;
        font-weight: normal !important;
    }
    input[type="checkbox"] {
        float: right;
        width: 100%;
    }
    input {
        width: auto !important;
    }
}
.checkbox-inline {
    margin-bottom: 10px;
}