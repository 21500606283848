.sticky-wrapper{
	height: auto !important;
}
nav.navbar{
	background: @white;
	margin-bottom: 0;
	border: 0;
	padding: 28px 0 28px;
	z-index: 999;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	overflow: hidden;
	.transition-prop(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
    box-shadow: 0 40px 65px -24px rgba(0,0,0,.08);

	@media (max-width: 1114px){
		padding: 15px 0;
	}



	.container-fluid{
		margin: 0 auto;
		max-width: 1200px;
		@media (max-width: @screen-md-max){
			margin: 0 3vw;
		}
	}
	.navbar-header{
		a.navbar-brand{
			padding: 0 0;
			height: 100%;
			border: 0;
			display: block;
            position: absolute;
            top: -10px;
            @media (max-width: 1114px){
                top: 0;
            }
			img{
				width: 237px;
				height: auto;
				@media (max-width: 1114px){
					width: 72px;
                    height: auto;
                    margin-top: 13px;
				}
			}
			&.down{
				display: none;
			}
		}
	}
	ul.topnav{
		list-style: none;
		padding-left: 0;
		margin-bottom: 24px;
		li{
			display: inline-block;
			margin-left: 8px;
			&.phone{
				background: @gray-color;
				font-size: 1.6em;
				.transition-prop(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
                @media (max-width: 768px){
                    display: none !important;
                }
				a{	
					border: 0;
					span{
						height: 40px;
						line-height: 40px;
						padding: 0 12px;
						color: @white;
						display: inline-block;
						i{
							line-height: 40px;
						}
					}
				}
				&:hover{
					background: @graydark-color;
				}
			}
			&.mail{
				font-size: 1.6em;
                @media (max-width: 768px){
                    display: none !important;
                }
				a{	
					border: 0;
					span{
						height: 40px;
						line-height: 40px;
						padding: 0 12px;
						color: @white;
						display: inline-block;
						&.icon{
							background: @yellow-color;

						}
						&.text{
							background: @yellowlight-color;
							text-decoration: none;
							line-height: 40px;
							.transition-prop(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
						}
					}

				}
				&:hover{
					a{
						border: 0;
						span{
							&.text{
								background: @yellowdark-color;
							}
						}
					}
				}
			}
		}

	}
	ul.navbar-nav{
		margin-bottom: 4px;
		overflow-x: hidden;
		li{
			margin: 0 2px 0 2px;
			overflow: hidden;
			&:after{
				display: block;
				height: 4px;
				width: 110%;
				background-image: linear-gradient(to right, @gray-color 50%, @yellow-color 50%);
				background-size: 200%;
				background-position: 0%;
				content: '';
				.transition-prop(background-position 500ms cubic-bezier(0.19, 1, 0.22, 1));
			}
			&.search{
				@media (max-width: 1114px){
					display: none;
				}
			}
			a{
				font-weight: 600;
				font-size: 16px;
				color: @gray-text;
				padding: 20px 17px;
				border: 0;

				&:hover{
					color: @gray-text;
				}
			}
			&:hover{
				&:after{
					background-position: -100%;
				}

			}
		}
	}

	.navbar-toggle{
		background: transparent;
		border: 0;
		padding: 15px 4px;
		&:hover, &:focus, &:active{
			background: transparent;
			span.icon-bar{
				background: @black;
			}	

		}
		span.icon-bar{
				position: relative;
				.transition-prop(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
				&:nth-child(3){
					.opacity(0);				
				}

				&:nth-child(2){
					.opacity(1);
					.transform-prop(rotate(-45deg));
					top: 6px;
				}
				&:nth-child(4){
					.opacity(1);
					.transform-prop(rotate(45deg));
					top: -6px;
				}
		}
		&.collapsed {
			span.icon-bar{
				background: @black;
				&:nth-child(3){
					.opacity(1);	
				}

				&:nth-child(2){
					.opacity(1);
					.transform-prop(rotate(0deg));
					top: 0px;
				}
				&:nth-child(4){
					.opacity(1);
					.transform-prop(rotate(0deg));
					top: 0px;
				}
			}
		}			

	}
	.collapsing, .in{
		border-top: 0;
		margin-top: 2vh;
		ul.navbar-nav{
			li{
				&:after{
					display: none;
				}
				a{
					padding: 11px 0;
					text-align: center;
					border: 0;
					&:focus, &:hover, &:active{
						color: @black;
					}
					@media (max-width: @screen-sm-max){
						padding: 7px 0;
					}
				}
			}
		}
		ul.topnav{
			margin-top: 8px;
			margin-bottom: 12px;
			text-align: center;
			li.mail{
				span.text{
					display: none;
				}
			}
			@media (max-width: @screen-sm-max) and (orientation: landscape){
				display: none !important;
			}
		}
	}

	&.down{
		top: -58px;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		padding: 0 0;
		.transform-prop(translateY(0));
		box-shadow: 0 40px 65px -24px rgba(0,0,0,.25);

		a.navbar-brand{
			display: none;
			img{
				max-width: 200px;
				height: auto;
                margin-top: 0 !important;
			}
			&.down{
				display: block;
				line-height: 60px;
                top: 0;
                
			}

		}
		ul.topnav{
			display: none;
		}
		.collapsing, .in{
			ul.topnav{
				display: block;
			}
		}
		ul.navbar-nav{
			margin-bottom: 0;
			li{
				margin: 0 0;
				&:after{
					display: none;
				}
				a{
					margin-bottom: 0;
					border: 0;
					&:hover{
						background: @yellow-color;
						color: @white;
					}
				}
			}
		}
	}
}



@media (max-width: 1114px) {
    .navbar-header {
        float: none;
    }
    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-fixed-top {
		top: 0;
		border-width: 0 0 1px;
	}
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
		margin-top: 7.5px;
	}
	.navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapsing, .collapse.in{
  		display:block !important;
  		height: 100vh !important;
	}
}

.searchcontainer{
	position: relative;
	@media (max-width: 1114px){
		display: none;
	}
	.close__searchbutton{
		height: 125px;
	    width: 30px;
	    position: relative;
	    cursor: pointer;
	    svg{
	    	position: absolute;
	    	top: 50%;
	    	left: 0;
	    	.transform-prop(translateY(-50%));
	    	polygon{
	    		fill: @white;
	    	}
	    }
	}
}

.searchbox{
	background-color: @yellow-color;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 997;
	right: 0;
	.opacity(0);
	.transform-prop(translateY(-100%));
	&.opened{
		.opacity(1);
	}
	input#searchinput{
		border: none;
		margin-left: 20px;
	    font-size: 2.5rem;
	    line-height: 125px;
	    background-color: transparent;
	    color: #fff;
	    color: @white;
	    width: 100%;
	    height: 125px;
	    font-size: 3em;
		&::-webkit-input-placeholder { 
		  color: @white;
		}
		&::-moz-placeholder { 
		  color: @white;
		}
		&:-ms-input-placeholder { 
		  color: @white;
		}
		&::-ms-input-placeholder { 
		  color: @white;
		}
		&:-moz-placeholder {
		  color: @white;
		}

		&:focus{
			outline: 0;
		}
	}
	input#searchsubmit{
		display: none;
	}
	div#searchenter{
		display: block;
		position: relative;
		width: 100%;
		cursor: pointer;

		svg{
			height: 50px;
			width: 50px;
			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: translateY(-50%) translateX(-50%);
			-moz-transform: translateY(-50%) translateX(-50%);
			-o-transform: translateY(-50%) translateX(-50%);
			-ms-transform: translateY(-50%) translateX(-50%);
		 	transform: translateY(-50%) translateX(-50%);
			path{
				fill: #fff;
			}
		}
	}
}

.resultsbox{
	background-color: @yellow-color;
	position: absolute;
	top: 0px;
	left: 0;
	z-index: 995;
	right: 0;
	.opacity(0);
	.transition-prop(transform 500ms cubic-bezier(0.19, 1, 0.22, 1));
	&.active{
		.opacity(1);
	}
    a{
        color: @white;
    }
	.results__cont{
        padding: 30px;
        color: @white;
        font-size: 20px;
        font-weight: 400;
		ul{
			list-style: none;
			margin-bottom: 0;
			padding-left: 0;
			li{
                a{
                    border-bottom: 0 !important;
                }
				    margin: 10px 0;
					color: @white;
					font-size: 22px;
					font-weight: 400;
					margin: 0 0;
					line-height: 50px;
					.transition-prop(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
					&:before{
						content:'';
						display: inline-block;
						width: 5px;
						height: 18px;
						background-color: white;
					    position: relative;
					    top: 2px;
					    left: 20px;
					    .opacity(0);
					    .transform-prop(translateX(0px));
					}

				&:hover{
						margin-left: 25px;
						&:before{
						    .opacity(1);
						    .transform-prop(translateX(-30px));
						}
				}
			}
		}

	}

}
#pnlSearch, #pnlSearch1, #UpdatePanel4{
    width: 100%;
}
#pnlSearch{
    width: 80%;
    float: left;
}
div.searchsmall{
	position: relative;
	display: block;
	margin-top: 18px;
	width: 80vw;
	margin-right: auto;
	margin-left: auto;
	@media (min-width: 1115px){
		display: none;
	}
}
.searchbox-small{
	display: block;
	min-width: 250px;
	clear: both;

	@media (min-width: 1114px){
		display: none;
	}
	input#searchsubmit1{
		display: none;
	}
	input#searchinput1{
		border: none;
	    font-size: 14px;
	    line-height: 40px;
	    height: 40px;
	    background-color: transparent; 
	    border-bottom: 2px solid @brand-primary;
	    color: #333;
	    padding-left: 20px;
	    width: 100%;
	    font-size: 1em;
		&::-webkit-input-placeholder { 
		  color: #333;
		}
		&::-moz-placeholder { 
		   color: #333;
		}
		&:-ms-input-placeholder { 
		   color: #333;
		}
		&::-ms-input-placeholder { 
		   color: #333;
		}
		&:-moz-placeholder {
		   color: #333;
		}

		&:focus{
			outline: 0;
		}
	}
	div#searchenter2{
		display: block;
		position: absolute;
		width: 100%;
		cursor: pointer;
		right: 0;
	    top: 0;
	    width: 40px;
	    height: 40px;
		svg{
			max-width: 22px;
			width: 100%;
			height: auto;
			position: absolute;
			width: 30px;
    		height: auto;
   			top: 50%;
			left: 50%;
			-webkit-transform: translateY(-50%) translateX(-50%);
			-moz-transform: translateY(-50%) translateX(-50%);
			-o-transform: translateY(-50%) translateX(-50%);
			-ms-transform: translateY(-50%) translateX(-50%);
		 	transform: translateY(-50%) translateX(-50%);
			path{
				fill: @brand-primary;
			}
		}
	}
}