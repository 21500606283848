
header.page__title{
	background-color: @graylight-color;
	padding: 20px 0;
	@media (max-width: @screen-md-max){
		padding-left:15px;
		padding-right: 15px;
	}

	h4.title{
		color: @gray-text;
		margin: 35px 0;
		font-size: 22px;
		text-transform: uppercase;
		font-size: 2.15em;

		&:before{
			top: 2px;
			width: 10px;
    		height: 23px;
		}
	}
}




.box__product {
    margin-bottom: 60px;

    a {
        border: 0;
    }

    .left {
        background-color: #e7e7e7;
        .transition-prop(all 500ms cubic-bezier(0.19, 1, 0.22, 1));

        img {
            margin-top: 18px;
            width: 100%;
            height: auto;
        }
    }

    .inner__content {
        background-color: @white;
        text-transform: uppercase;
        padding: 25px;
        font-size: 14px;
        margin-bottom: 0;
        .transition-prop(all 500ms cubic-bezier(0.19, 1, 0.22, 1));

        h6 {
            float: left;
            width: 100%;
            font-size: 17px;
            margin-top: 0;
            color: @gray-text;
        }
        a{
            color: @gray-text !important;
        }
    }

    &:hover {
        .left {
            background-color: @yellow-color;
        }
    }
}


section.product__grid{
	padding-bottom: 40px;

	@media (max-width: @screen-md-max){
		padding-left:15px;
		padding-right: 15px;
	}
	a{
		border: 0;
	}

	.main__cat{
        position: relative;
        .transition-prop(opacity 500ms ease-in-out);
        .opacity(1);
        &.hidden{
            .opacity(0);
            .transition-prop(opacity 500ms ease-in-out);
        }


		h5{
			text-align: center;
			background-color: @graydark-color;
			color: @white;
			padding: 10px 25px;
			font-size: 18px;
            line-height: 40px;
            position: relative;
            &:after{
                content: '';
                position: absolute;
                left: 50%;
                width: 0;
                height: 0;
                bottom: -10px;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 10px solid @graydark-color;
                .transform-prop(translateX(-50%));
                clear: both;
            }
            @media (max-width: @screen-md-max){
            	line-height: 25px;
            	&:after{
            		bottom: -8px;
            	}
            }
		}
		.box__mainproduct{
			margin: 15px 0;
			min-height: 95px;
			.left{
				background-color: #e7e7e7;
				.transition-prop(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
				img{
					margin-top: 18px;
					width: 100%;
					height: auto;
				}
			}
			@media (max-width: @screen-md-max){
				margin: 10px 0;
			}
			.inner__content{
				background-color: @graylight-color;
				text-transform: uppercase;
				padding: 25px;
				.transition-prop(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
				h6{
					float: left;
					width: 100%;
					font-size: 17px;
					margin-top: 0;
					color: @gray-text;
					.transition-prop(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
				}
				span{
					color: @gray-text;
				}

			}

			&:hover{
				.left{
					background-color: @yellowdark-color;
				}
				.inner__content{
					background-color: @yellow-color;
					h6{
						color: @white;
						padding-left: 18px;
					}
					span{
						color: @white;
					}

				}
			}
		}
	}
	.box__product{
		margin: 12px 0;
		position: relative;
		min-height: 250px;
		overflow: hidden;
		.content{
			position: relative;
			width: 100%;
			overflow: hidden;
            h6{
                margin-top: 0;
                margin-bottom: 0;
			    a{
				    background-color: @graydark-color;
				    width: 100%;
				    padding: 12px 25px;
				    position: absolute;
				    bottom: 0;
				    right: 0;
				    left: 0;

			        font-size: 18px;
				    color: @white;
				    margin-bottom: 0;

			    }
            }
            .container_img{
				width: 100%;
				min-height: 250px;
				background-size: cover;
				background-position: 40% 40%;
				.transform-prop(scale(1.05,1.05));
				.transition-prop(all 1000ms cubic-bezier(0.19, 1, 0.22, 1));
				display: flex;

            }
			a.background__cat{
				text-indent: -99999px;
				color: transparent;
				display: flex;
				flex-direction: column;
				flex: 1;
  				flex-grow: 1;
				flex-shrink: 1;
				flex-basis: auto;
			}
		}
		&:hover{
			.content{
				.container_img{
					.transform-prop(scale(1.15,1.15));
				}
			}

			h6{
				a{
					    background-color: @brand-primary;
                        padding-bottom: 15px;
				}
			}

		}

	}

}

section.single__product{
	padding: 50px 0;
	color: @gray-text;
	.content{
		padding-top: 15px;
		font-size: 1.4em;
		h1.title{
			color: @gray-text;
			font-weight: 600;
			font-size: 24px;
			margin: 0 0 30px;
		}

	}

	.image__featured{
		width: 100%;
		min-height: 360px;
		background-position: 50% 50%;
		background-size: cover;
	}
	.sidebar__nav{
		padding-left: 0;
		a{
			border: 0;
		}
	}
	nav.categories{
		@media (max-width: @screen-md-max){
			margin-top: 40px;
			margin-left: 15px;
		}
		ul{
			list-style: none;
			margin-bottom: 0;
			padding-left: 0;
			li{
				background-color: #f2f2f2;
				margin: 5px 0;

				a{
					font-weight: 600;
					color: @gray-text;
					text-transform: uppercase;
					width: 100%;
					padding: 17px 20px;
					display: block;
					font-size: 14px;
					.transition-prop(all 300ms cubic-bezier(0.19, 1, 0.22, 1));
                    &.submenu-open{
                        background: darken(@yellow-color,5%);
                        color: @white;
                    }
				}

				ul.sub-menu{
					display: none;
                    &.active{
                        display: block;
                    }
					
					li{
						margin: 0 0;
						background-color: #f9f9f9;
						a{
							padding: 10px 0 10px 40px;
							font-size: 14px;
						}
						
					}
				}
                
				&.active, &:hover{
					& > a{
						background-color: @yellow-color;
						color: @white;
					}

				}
			}
		}
	}
}